import BaseMethods from '../BaseMethods';
import {getData} from "@/api/v2/utils/DataHandler";

export default new (class extends BaseMethods {
    endpointRoute = '/api/v2/company-invitations';

    async import(data) {
        const url = `${this.endpointRoute}/import`;

        return this.asFormData().post(url, data).then(getData);
    }

    async accept(data) {
        const url = `${this.endpointRoute}/accept`;

        return this.asJson().post(url, data).then(getData);
    }

    async token(params) {
        const url = `${this.endpointRoute}/token`;

        return this.asJson().get(url, {params}).then(getData);
    }

    async resend(id) {
        const url = `${this.endpointRoute}/${id}/resend`;

        return this.asJson().get(url).then(getData);
    }

    async cancel(id) {
        const url = `${this.endpointRoute}/${id}/cancel`;

        return this.asJson().get(url).then(getData);
    }
})();
